<template>
    <div class="profile">
        <profileheader :image="getProfileImage" :firstname="profile.firstName" :lastname="profile.lastName" :division="profile.workPosition" />

        <div class="error" v-if="error.length">
            <p v-for="(err,idx) in error" :key="idx">{{$t(err)}}</p>
        </div>
        <template v-if="workEditable || privateEditable">
            <div class="profile-data">
                <p>
                    <span>{{$t('profile_firstname')}}</span>
                    <input type="text" required v-model="profile.firstName">
                </p>
                <p>
                    <span>{{$t('profile_lastname')}}</span>
                    <input type="text" required v-model="profile.lastName">
                </p>
            </div>
        </template>

        <div class="profile-data">
            <h4>Dienstliche Pflichtangaben<span @click="editprofile('work')" class="action">
            <inline-svg v-if="!workEditable" :src="require('@/assets/images/ic_edit.svg')" width="18" />
                <inline-svg v-else :src="require('@/assets/images/ic_save.svg')" width="18" />
            </span></h4>

            <p class="data-division">
                <span>{{ $t('profile_division') }}</span>
                <span v-html="profile.division" v-if="!workEditable"></span>
                <select v-model="profile.division" v-else required>
                    <option value="">Sachgebiet auswählen ...</option>
                    <option v-for="(item,idx) in sachgebiete" :value="item" :key="idx" v-html="item" />
                </select>
            </p>

            <p class="data-workposition">
                <span>{{ $t('profile_work_position') }}</span>
                <span v-html="profile.workPosition" v-if="!workEditable"></span>
                <input v-else type="text" :readonly="!workEditable" v-model="profile.workPosition" :class="{'is-empty': profile.workPosition === ''}" />
            </p>

            <p class="data-workscope">
                <span>{{ $t('profile_work_scope') }}</span>
                <span v-html="profile.workScope" v-if="!workEditable"></span>
                <input v-else type="text" required :readonly="!workEditable" v-model="profile.workScope" :class="{'is-empty': profile.workScope === ''}" />
            </p>

            <p class="data-telefon">
                <span>{{ $t('profile_telephone') }}</span>
                <input type="text"
                       :readonly="!workEditable"
                       :class="{'is-empty': profile.telephone === ''}"
                       :placeholder="$t('profile_telephone_placeholder')"
                       v-model="profile.telephone"
                       required
                       pattern="[0-9]{3,5} ([0-9]{2,}-)?[0-9]+" />
                <span v-show="workEditable" class="description">Format: {{$t('profile_telephone_placeholder')}}</span>
            </p>
            <p class="data-telefon">
                <span>{{ $t('profile_telephone_mobile') }} <small>{{ $t('optional') }}</small></span>
                <input type="text" :readonly="!workEditable" v-model="profile.telephoneMobile" :class="{'is-empty': profile.telephoneMobile === ''}" />
            </p>
            <p class="data-email">
                <span>{{ $t('profile_email') }}</span>
                <span v-if="!workEditable" v-html="profile.email"></span>
                <input v-else type="email" required :readonly="!workEditable" v-model="profile.email" :class="{'is-empty': profile.email === ''}" />
            </p>

        </div>

        <div id="privat" class="profile-data">
            <h4>Persönliche Angaben <span @click="editprofile('private')" class="action">
                <inline-svg v-if="!privateEditable" :src="require('@/assets/images/ic_edit.svg')" width="18" />
                <inline-svg v-else :src="require('@/assets/images/ic_save.svg')" width="18" />
            </span></h4>
            <p  key="telephone2">
                <span>{{ $t('profile_telephone2') }} <small>{{ $t('optional') }}</small></span>
                <input type="text" :readonly="!privateEditable" v-model="profile.telephone2" :class="{'is-empty': profile.telephone2 === ''}" />
            </p>
            <p  key="telephoneMobile2">
                <span>Handy <small>{{ $t('optional') }}</small></span>
                <input type="text" :readonly="!privateEditable" v-model="profile.telephoneMobile2" :class="{'is-empty': profile.telephoneMobile2 === ''}" />
            </p>
            <p  key="emailPrivat">
                <span>E-Mail-Adresse <small>{{ $t('optional') }}</small></span>
                <span v-if="!privateEditable" v-html="profile.emailPrivat"></span>
                <input v-else type="email" :readonly="!privateEditable" v-model="profile.emailPrivat" :class="{'is-empty': profile.emailPrivat === ''}" />
            </p>
            <p  key="birthday">
                <span>{{ $t('profile_birthday')  }} <small>{{ $t('optional') }}</small></span>
                <input v-if="privateEditable" type="date" :readonly="!privateEditable" v-model="birthday" :class="{'is-empty': profile.birthday === 0}" />
                <span v-else-if="profile.birthday">{{ $filters.formatDate(profile.birthday, 'j.m.Y') }}</span>
                <span v-else> </span>
            </p>

            <p key="address" >
                <span>Wohnort <small>{{ $t('optional') }}</small></span>
                <input type="text" placeholder="Stadt" v-model="profile.city" v-if="privateEditable">
                <span v-else>{{profile.city}}</span>
            </p>

            <p  key="expertise">
                <span>{{ $t('profile_expertise') }}</span>
                <textarea v-if="privateEditable"
                       :readonly="!privateEditable"
                       v-model="profile.expertise"
                          :placeholder="$t('profile_expertise_placeholder')"
                       :class="{'is-empty': profile.expertise === ''}" />
                <span v-else>{{ profile.expertise }}</span>
            </p>

            <p  key="devise">
                <span>{{ $t('profile_devise')  }}</span>
                <textarea v-if="privateEditable"
                          :readonly="!privateEditable"
                          v-model="profile.devise"
                          :class="{'is-empty': profile.devise === ''}" />
                <span v-else v-html="profile.devise"/>
            </p>

            <p  key="hobby">
                <span>{{ $t('profile_hobby') }}</span>
                <textarea v-if="privateEditable"
                          type="text"
                          :readonly="!privateEditable"
                          v-model="profile.hobby"
                          :class="{'is-empty': profile.hobby === ''}" />
                <span v-else v-html="profile.hobby" />

            </p>
        </div>

        <div v-if="notificationsSupported" id="notification" class="profile-data profile-data--checkbox">
            <h4>Benachrichtigungen</h4>

<!--            <p><label>
                <span>Wichtige Meldungen (Empfohlen)</span>
                <input type="checkbox"
                       v-model="notificationsMain"
                       value="1"
                       :checked="notificationsMain"
                       autocomplete="off"
                       class="sr-only"><span class="nice_checkbox"></span>
            </label></p>
            <p><label>
                <span>Aktuelle Meldungen</span>
                <input type="checkbox"
                       v-model="notificationsNews"
                       value="1"
                       :checked="notificationsNews"
                       autocomplete="off"
                       class="sr-only"><span class="nice_checkbox"></span>
            </label></p>
            <p><label>
                <span>Anstehende Kalender-Einträge</span>
                <input type="checkbox"
                       v-model="notificationsEvents"
                       value="1"
                       :checked="notificationsEvents"
                       autocomplete="off"
                       class="sr-only"><span class="nice_checkbox"></span>
            </label></p>-->

            <p v-for="(isSet, tag) in this.pushCategories" :key="tag"><label>
                <span>{{ $t('push_' + tag.replace('-','_') ) }}</span>
                <input type="checkbox"
                       v-model="this.pushCategories[tag]"
                       value="1"
                       :checked="isSet"
                       autocomplete="off"
                       class="sr-only"><span class="nice_checkbox"></span>
            </label></p>

        </div>

        <div id="view" class="profile-data  profile-data--checkbox">
            <h4>Darstellung</h4>
            <p><label>
                <span>Modus "Hoher Kontrast"</span>
                <input type="checkbox"
                       v-model="darkMode"
                       value="1"
                       :checked="darkMode"
                       autocomplete="off"
                       class="sr-only"><span class="nice_checkbox"></span>
            </label></p>
        </div>

        <div class="profile-data">
            <h4>Konto</h4>
            <transition name="fade"><span v-if="success" v-html="success" class="message message--success"></span></transition>
            <span v-on:click="showPasswdForm" v-if="!passwdForm" class="button button--secondary">Passwort &auml;ndern</span>
            <transition name="slide-up">
                <form action="#" v-show="passwdForm" class="profile-form profile-form--password" autocomplete="off">
                    <transition name="fade"><span v-if="error.length != 0" v-html="error" class="message message--error"></span></transition>

                    <p class="form-fieldwrap">
                        <label>altes Password</label>
                        <input type="password" v-model="oldPasswd" id="oldPasswd" placeholder="altes Passwort" autocomplete="off">
                        <!--<label for="oldPasswd">altes Passwort</label>-->
                    </p>
                    <p class="form-fieldwrap">
                        <label>neues Passwort</label>
                        <input :type="passwordType" v-model="newPasswd" id="newPasswd" placeholder="neues Passwort" autocomplete="off" />
                        <!--                            <label for="newPasswd">neues Passwort</label>-->
                        <span v-on:click="passwdVisible = !passwdVisible" class="inputToggle"><i :class="(passwdVisible ? 'icon-eye-off' : 'icon-eye')"></i></span>
                    </p>
                    <button type="button" class="button button--secondary" v-on:click="submitPassword">Passwort speichern</button>
                </form>
            </transition>
            <button class="button button--primary profile-logout" v-on:click="logout">Abmelden</button>
        </div>
    </div>
</template>

<script>
// import store from "@/utils/store";
import { USER_UPDATE } from "@/utils/store/actions/user";
import { AUTH_LOGOUT } from "@/utils/store/actions/auth";
import profileheader from './Profileheader';
import { mapGetters } from 'vuex';

export default {
	name: "KappProfile",
	components: {
		profileheader
		// 'kapp-header': Header,
		// 'lightbox': Lightbox
	},
	data() {
		return {
			profile: false,
			darkMode: this.$store.getters.getViewMode === 'dark',
			notificationsMain: false,
			notificationsNews: false,
			notificationsEvents: false,
			notifications: false,
			passwdForm: false,
			passwdVisible: false,
			newPasswd: '',
			oldPasswd: '',

			error: [],
			success: false,

			profileVisible: false,

			workEditable: false,
			privateEditable: false,
			dataChanged: false,

			sachgebiete: [
				'Landrat/stv. Landrat',
				'SG 15 – Kreisentwicklung, Wirtschaftsförderung, Naturpark',
				'SG 10 – Büro des Landrats, Presse- und Öffentlichkeitsarbeit',
				'S 1 – Kreisrechnungsprüfungsamt',
				'SG 16 – Gesundheit und Pflege, Gesundheitsregionplus Nordoberpfalz',
				'S 2 – Datenschutz und Informationssicherheit',
				'S 3 – Erhebungsstelle Zensus',
				'AL 1 – Zentrale Angelegenheiten',
				'SG 11 – Haupt- und Personalverwaltung',
				'SG 12 – Kreisfinanzverwaltung',
				'SG 13 – Informationstechnologien (IT)',
                'SG 14 – Digitalisierung',
				'AL 2 – Kommunale und soziale Angelegenheiten',
				'SG 21/22 – Kommunalaufsicht, Schulen und ÖPNV',
				'SG 23 – Staatliche Rechnungsprüfungsstelle',
				'SG 24 – Sozialwesen',
				'SG 25 – Kreisjugendamt',
				'AL 3 – Öffentliche Sicherheit und Ordnung, Abfallwirtschaft',
				'SG 31 – Öffentliche Sicherheit und Ordnung, Zentrale Bußgeldstelle',
				'SG 32 – Ausländerrecht, Personenstandswesen',
				'SG 33 – Verkehrswesen',
				'SG 34 – Gesundheitswesen (rechtlich)',
				'SG 35 – Kommunale Abfallwirtschaft',
				'AL 4 – Bauwesen und Umweltschutz',
				'SG 41 – Umweltschutz, Naturschutz, Landschaftspflege',
				'SG 42 – Bauordnung (rechtlich), Wohnungs-/Planungswesen, Denkmalschutz',
				'SG 42 - Bauamt (rechtlich), Wohnungs- und Planungswesen und Untere Denkmalschutzbehörde',
				'SG 43 – Wasserrecht',
				'SG 44 - Bauamt (technisch) und Untere Denkmalschutzbehörde',
				'SG 44 – Bauordnung (technisch) und Bauüberwachung',
				'SG 45 – Bodenschutz und staatliches Abfallrecht',
				'AL 5 - Kreisbaumeister',
				'SG 51 – Zentrale Vergabestelle',
				'SG 52 – Hochbau, Tiefbau, Gartenfachberatung',
                'Personalratsvorsitzende',
				'Geschäftsstelle Gutachterausschuss',
				'Abt. 6 – Gesundheitswesen',
				'Abt. 7 – Veterinärwesen, Verbraucherschutz',
				'Jobcenter',
				'Kreisjugendring',
                'Medienzentrum Neustadt-Weiden',
				'Externe Agentur C3 und PPM'
			],

            pushCategories: {
				'news-spotlight': false,
				'news-pressemitteilung': false,
				'news-hausintern': false,
				'veranstaltungen': false,
				'post-allgemein': false,
				'post-suche': false,
				'post-biete': false,
				'post-events': false
				// 'wichtige-meldungen': false,
			}
		};
	},
	created() {
		this.profile = Object.assign({}, this.getProfile);
		window.UA.then(( sdk ) => {
			sdk.getChannel().then(( channel ) => {
				if ( channel.optedIn === false ) {
					Object.keys(this.pushCategories).forEach((tagsKey)=>{
                        this.pushCategories[ tagsKey ] = true;
					})
				}
				else {
					Object.keys(this.pushCategories).forEach((tagsKey)=>{
						if ( channel.tags.has(tagsKey) ) {
							this.pushCategories[ tagsKey ] = true;
						}
					},channel)
				}
			});
		});
	},
	methods: {
		checkData: function(target){

			let checkingKeys = [ 'firstName', 'lastName' ];
			if ( target === 'work' ) {
				checkingKeys = checkingKeys.concat([ 'division', /*'workPosition',*/ 'workScope', 'telephone', 'email' ]);
			}
			else {
				checkingKeys = checkingKeys.concat([]);
			}
			let valid = true;
			checkingKeys.forEach(( key ) => {

				if( key === 'telephone' ) {
					let re = new RegExp(/^[0-9]{3,5} ([0-9]{2,}-)?[0-9]+$/);
					if( false === re.test(this.profile[ key ]) ) {
						valid = false;
						this.error.push( 'error_profile_' + key );
					}
				}
				else if ( this.profile[ key ] === '' ) {
					valid = false;
					this.error.push( 'error_profile_' + key );
				}

			});

			return valid;
        },
		editprofile: function ( target ) {
			let save = false;
			if ( target === 'work' ) {
				if( this.workEditable ) {
					save = this.checkData('work');

					if( save === false ) {
						// this.$toast.error('Es wurden nicht alle Pflichtangaben ausgefüllt oder sind falsch formatiert!');
                    }
					else{
						this.workEditable = false;
                    }
				}
				else{
                    this.workEditable = true;
                }

			}

			if ( target === 'private' ) {
				if( this.privateEditable ) {
					save = this.checkData('work');
					if( save === false ) {
						this.$toast.error('Es wurden nicht alle Pflichtangaben ausgefüllt oder sind falsch formatiert!');
                    }
					else{
						this.privateEditable = false;
                    }
				}
				else{
                    this.privateEditable = true;
                }

			}

			if ( save ) {
				let data = Object.assign({}, this.profile, {action: 'update'});
				this.$store.dispatch(USER_UPDATE, data)
                    .then(( ) => {
                        // console.log(response);
                        this.$toast.success('Änderungen wurden gespeichert', {
                            position: 'top'
                        });
                    })
                    .catch(( ) => {
                        // console.log(response);
                        this.$toast.error('Es ist leider ein Fehler aufgetreten!', {
                            position: 'top',
                            duration: 5000
                        });
				});
			}
		},

		showPasswdForm: function () {
			if ( this.passwdForm === false )
				this.passwdForm = !this.passwdForm;
		},
		submitPassword: function () {
			let formData = {
				newPasswd: this.newPasswd,
				oldPasswd: this.oldPasswd
			};
			this.$store.dispatch(USER_UPDATE, formData)
				.then(( response ) => {
					console.log( response );
					this.error = [];
					this.passwdForm = false;
					this.success = response.message;
					setTimeout(function () {
						this.success = false;
					}.bind(this), 2000);
				})
				.catch(( err ) => {
					this.error.push(err.message);
					setTimeout(function () {
						this.error = [];
					}.bind(this), 2000);

				});
		},
		logout: function () {
			setTimeout(function () {
				this.$store.dispatch(AUTH_LOGOUT).then(() => {
					this.$router.push({name: 'Login'});
				});
			}.bind(this), 300);
		},
		notificationSwitch: function ( src,val ) {

			window.UA.then(( sdk ) => {
				if ( val === true ) {
					if ( !sdk.isRegistered ) {
						sdk.register().then( function(){
							sdk.channel.tags.add(src);
                        });
					}
					else {
						sdk.channel.tags.add(src);
					}
				}
				else {
					sdk.channel.tags.remove( src );
				}
			});
		}
	},
	computed: {
		notificationsSupported: function(){
			return ('Notification' in window);
		},
		birthday: {
			get: function () {
				if( this.profile.birthday === 0) return null;

				let date = new Date();
				date.setTime(this.profile.birthday * 1000);
				date.resetTo('hour');
				return date.format('Y-m-j');
			},
			set: function ( val ) {
				let date = new Date();
				val = val.split('-');
				if( parseInt(val[0]) > 1000 ) {
					date.setFullYear(val[ 0 ], (val[ 1 ] - 1), val[ 2 ]);
					this.profile.birthday = Math.round(date.getTime() / 1000);
				}
				else {
					this.profile.birthday = 0;
                }
			}
		},
		passwordType: function () {
			return this.passwdVisible ? 'text' : 'password';
		},
        formatedAddress: function(){
			let address = '';
			if( this.profile.address !== '' )
				address += this.profile.address + '<br />';
			if( this.profile.zip !== '' )
				address += this.profile.zip + ' ';
			if( this.profile.city !== '' )
				address += this.profile.city;

			return address;
        },
        ...mapGetters(['getProfile','getProfileImage'])
	},
	watch: {
		darkMode: function () {
			this.$store.commit('CHANGE_VIEWMODE');
		},

		'pushCategories.news-spotlight': function(newVal){
			this.notificationSwitch('news-spotlight',newVal,'news');
		},
		'pushCategories.news-pressemitteilung': function(newVal){
				this.notificationSwitch('news-pressemitteilung',newVal,'news');
		},
		'pushCategories.news-hausintern': function(newVal){
				this.notificationSwitch('news-hausintern',newVal,'news');
		},
		'pushCategories.veranstaltungen': function(newVal){
				this.notificationSwitch('veranstaltungen',newVal,'kalender');
		},
		'pushCategories.post-allgemein': function(newVal){
				this.notificationSwitch('post-allgemein',newVal,'posts');
		},
        'pushCategories.post-suche': function(newVal){
				this.notificationSwitch('post-suche',newVal,'posts');
		},
		'pushCategories.post-biete': function(newVal){
				this.notificationSwitch('post-biete',newVal,'posts');
		},
		'pushCategories.post-events': function(newVal){
				this.notificationSwitch('post-events',newVal,'posts');
		},
		// notificationsEvents: function ( val ) {
		// 	this.notificationSwitch('notificationsEvents', val);
		// },
		// notificationsNews: function ( val ) {
		// 	this.notificationSwitch('notificationsNews', val);
		// }
	}
};
</script>

